






































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import SubSystemComponent from '@common-src/mixins/sub-system-component';
import { AlarmEventEntityModel, AlarmEventQueryModel, AlarmEventTableColumns } from '@common-src/entity-model/alarm-event-entity';
import AlarmEventService from '@common-src/service/alarm-event';
import AlarmEventDrawer from './alarm-event-drawer.vue';
import { SubSystemType, ViewModeType } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';

@Component({
    components: {
        'alarm-event-drawer': AlarmEventDrawer
    }
})
export default class AlarmEventListComponent extends Mixins(TableComponent, SubSystemComponent, FormDialogComponent) {
    dialogVisible: boolean = false;
    currentEventId: string = null;
    currentEventTitle: string = null;
    currentEventIndex: number = null;
    pageTitle: string = null;
    pagePath: string = '/';
    pageSubTitle: string = null;

    created() {
        let queryModel = new AlarmEventQueryModel(this.SubSystemName);
        let columns = AlarmEventTableColumns;
        if (SubSystemType.ALARM === this.SubSystemName) {
            queryModel.active = true;
        } else if (SubSystemType.ALARM_HISTORY === this.SubSystemName) {
            queryModel.active = false;
            queryModel.dateRange = JTL.CONSTANT.TODAY();
        }
        this.initTable({ listFunc: AlarmEventService.query, queryModel, tableColumns: columns });
        this.getList();
    }
    initPage() {
        // let queryModel = null;
        // queryModel = new AlarmEventQueryModel(this.SubSystemName);
        switch (this.SubSystemName) {
            case SubSystemType.ALARM:
                this.pageTitle = '首页';
                this.pageSubTitle = '正在告警';
                this.pagePath = '/';
                (this.queryModel as any).active = true;
                (this.queryModel as any).dateRange = undefined;
                break;
            case SubSystemType.ALARM_HISTORY:
                this.pageTitle = '首页';
                this.pageSubTitle = '历史告警';
                this.pagePath = '/';
                (this.queryModel as any).active = false;
                (this.queryModel as any).dateRange = JTL.CONSTANT.TODAY();
                break;
            case SubSystemType.PARKING:
                this.pageTitle = '停车场管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/parking-index';
                break;
            case SubSystemType.FIRE:
                this.pageTitle = '消防管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/fire-index';
                break;
            case SubSystemType.CAMERA:
                this.pageTitle = '监控管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/camera-index';
                break;
            case SubSystemType.ENVIROMENT:
                this.pageTitle = '环境管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/env-index';
                break;
            case SubSystemType.ENERGY:
                this.pageTitle = '能源管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/energy-index';
                break;
            case SubSystemType.BA:
                this.pageTitle = '楼宇自控管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/ba-index';
                break;
            case SubSystemType.CUSTOMER_FLOW:
                this.pageTitle = '客流系统';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/customer-flow-index';
                break;
            case SubSystemType.ELEVATOR:
                this.pageTitle = '电梯管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/elevator-index';
                break;
            case SubSystemType.LIGHT:
                this.pageTitle = '照明管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/light-index';
                break;
            case SubSystemType.ACCESS_CONTROL:
                this.pageTitle = '门禁管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/access-control-index';
                break;
            case SubSystemType.COMPUTER_ROOM:
                this.pageTitle = '机房动环管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/computer-room-index';
                break;
        }
        (this.queryModel as AlarmEventQueryModel).subSystemType = this.SubSystemName;
        (this.queryModel as AlarmEventQueryModel).reset();
        (this.$refs[JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME] as any).initQuery(this.queryModel);
        // if ([SubSystemType.ALARM, SubSystemType.ALARM_HISTORY].indexOf(this.SubSystemName) === -1) {
        //     // this.getList();
        // }
    }

    detailClick(model: any, index: number) {
        this.dialogVisible = true;
        this.currentEventId = model.id;
        this.currentEventTitle = model.eventName;
        this.currentEventIndex = index;
    }

    handleClick(model: any, isBatch: boolean) {
        let selectModel = null;
        let dialogTitle = null;
        if (isBatch) {
            if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
                this.showMessageWarning('请先选择要处理的告警');
                return;
            }
            selectModel = new AlarmEventEntityModel(this.selectedRowKeys);
            selectModel.recover = (SubSystemType.ALARM === this.SubSystemName) ? 0 : 1;
            dialogTitle = '批量事件处理';
        } else {
            selectModel = new AlarmEventEntityModel([model.id]);
            selectModel.recover = (SubSystemType.ALARM === this.SubSystemName) ? 0 : 1;
            dialogTitle = '事件处理';
        }
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogOpen(selectModel, AlarmEventService, ViewModeType.UPDATE);
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogTitle = dialogTitle;
    }
    onDrawerClose() {
        this.dialogVisible = false;
        this.currentEventId = null;
    }
    eventChange(step: number) {
        const nextIndex = this.currentEventIndex + step;
        if (nextIndex < 0) {
            this.showMessageInfo('已经是第一个了');
            return;
        }
        if (nextIndex > this.listData.length - 1) {
            this.showMessageInfo('已经是最后一个了');
            return;
        }
        this.detailClick(this.listData[nextIndex], nextIndex);
    }
    exportClick() {
        this.startFullScreenLoading('正在导出Excel...');
        AlarmEventService.exportExcel(this.queryModel as AlarmEventQueryModel).then(res => {
            this.showMessageSuccess('成功导出Excel');
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
    // eventHandled() {
    //     // this.onDrawerClose();
    //     // this.currentEventId = '';
    //     // this.page = 1;
    //     this.getList();
    // }
}

